import React, { Component} from 'react';
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Img from "gatsby-image"
const ReactMarkdown = require('react-markdown')

class BlogPost extends Component {
  render() {
    const blogPostData = this.props.data.contentfulBlogPost;

    console.log(blogPostData)

    return (
      <Layout>
        <SEO title={blogPostData.title + ' - Krasniy Report'} keywords={[`gatsby`, `application`, `react`]} />

        <h1>{blogPostData.title}</h1>
        <Img fluid={blogPostData.heroImage.fluid} style={{'maxWidth':'300px'}}/>
        <ReactMarkdown source={blogPostData.body.body} />
         
      </Layout>
    )
  }
}


export default BlogPost


export const BlogPostByID = graphql`
  query BlogPostByID($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
          id
          title
          author {
            id
            slug
          }
          heroImage
            {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
            }
            body {
              body
            }
        }       
  }
`




